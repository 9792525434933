import cx from "classix";

type Props = {
  tag: keyof JSX.IntrinsicElements;
} & React.HTMLAttributes<HTMLOrSVGElement>;

export default function Container({
  tag: Wrapper = "div",
  children,
  className,
  ...rest
}: Props) {
  return (
    <Wrapper {...rest} className={cx("px-4 layout-container", className)}>
      {children}
    </Wrapper>
  );
}
