import {
  Bars3Icon,
  ChevronUpIcon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { i18n } from "lib/i18n";
import Image from "next/image";
import { cx } from "classix";

import Link from "next/link";

import LocaleSelector from "./LocaleSelector";

import logoBlack from "public/images/afi-par-edgenda-black.svg";
import logo from "public/images/logo.svg";

export default function Navbar() {
  const { t } = useTranslation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const services = [
    {
      name: "Expériences d'apprentissage",
      description: "Créations d'expériences d'apprentissage",
      href: `/${i18n.language}/experiences`,
      disabled: true,
    },
    {
      name: "Apprentissages numériques",
      description: "Solutions rapides d'apprentissage en ligne",
      href: `/${i18n.language}/develop`,
      disabled: true,
    },
    {
      name: t("custom_programs"),
      description: t("custom_programs_description"),
      href: `/${i18n.language}/programs`,
    },
    {
      name: t("business_coaching"),
      description: t("business_coaching_description"),
      href: `/${i18n.language}/coaching`,
    },
    {
      name: "Innovation",
      description: "Technologies émergentes en apprentissage",
      href: `/${i18n.language}/lab`,
      disabled: true,
    },
  ];

  return (
    <>
      <nav
        className="flex items-center justify-between gap-6 text-base font-semibold text-white"
        aria-label="Global"
      >
        <Link className="flex-shrink-0" href={`/${i18n.language}`}>
          <Image
            className="mt-10"
            src={logo}
            width={131}
            height={147}
            alt="logo"
          />
        </Link>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="ml-auto hidden items-center lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 leading-6 hover:underline">
              {t("services")}
              <ChevronDownIcon
                className="h-5 w-5 flex-none"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-48 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white text-black shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {services
                    .filter((e) => !e.disabled)
                    .map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-yellow-300"
                      >
                        <i className="h-6 w-6 rounded-full border-[1px] border-solid border-red"></i>
                        <div className="flex-auto">
                          <Link href={item.href} className="block font-bold">
                            {item.name}
                            <span className="absolute inset-0" />
                          </Link>
                          <p className="mt-1 font-normal">{item.description}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Link className="hover:underline" href={t("team_link") as string}>
            {t("our_company")}
          </Link>
          <Link className="hover:underline" href={t("careers_link") as string}>
            {t("careers")}
          </Link>
          <Link
            className="hover:underline"
            href={t("contact_us_link") as string}
          >
            {t("contact")}
          </Link>
          <LocaleSelector className="hover:underline" />
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-pink-salt px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-start justify-between">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <Image src={logoBlack} width={64} height={72} alt="logo" />
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex gap-4 flex-col mt-10">
                <MobileNavMenuDropdown title={t("services")} items={services} />
                <Link
                  href={t("team_link") as string}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-bold leading-7 hover:bg-yellow-300"
                >
                  {t("our_company")}
                </Link>
                <Link
                  href={t("careers_link") as string}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-bold leading-7 hover:bg-yellow-300"
                >
                  {t("careers")}
                </Link>
                <Link
                  href={t("contact_us_link") as string}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-yellow-300"
                >
                  {t("contact")}
                </Link>
              </div>
              <div className="py-6">
                <LocaleSelector className="hover:underline" />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}

type MobileNavMenuDropdownProps = {
  title: string;
  items: {
    name: string;
    href: string;
    disabled?: boolean;
  }[];
};

function MobileNavMenuDropdown({ title, items }: MobileNavMenuDropdownProps) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        className="flex items-center w-full font-bold text-base -mx-3 rounded-lg px-3 py-2 leading-7 hover:bg-yellow-300"
        onClick={() => setOpen((v) => !v)}
      >
        {title}
        {open ? (
          <ChevronUpIcon className="ml-auto h-4 w-4" />
        ) : (
          <ChevronDownIcon className="ml-auto h-4 w-4" />
        )}
      </button>
      <ul className={cx(open ? "block" : "hidden", "ml-4 space-y-2 mt-2")}>
        {items
          .filter((e) => !e.disabled)
          .map((item) => (
            <li
              className="px-3 py-2 rounded-lg hover:bg-yellow-300"
              key={`mobile_link_${item.name}`}
            >
              <Link className="text-sm hover:underline" href={item.href}>
                {item.name}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}
