import Link from "next/link";
import { useRouter } from "next/router";

const langMap: { [locale: string]: string } = {
  fr: "FR",
  en: "EN",
};

type Props = {
  className?: string;
};

export default function LocaleSelector({ className }: Props) {
  const { asPath, locale: currentLocale } = useRouter();
  const otherLocale = currentLocale == "en" ? "fr" : "en";
  return (
    <Link
      className={className}
      key={otherLocale}
      href={asPath}
      locale={otherLocale}
    >
      {langMap[otherLocale]}
    </Link>
  );
}
