function appendHttps(url: string) {
  return `https:${url}`;
}

type ContentfulImageParams = {
  width?: number;
  height?: number;
  fit?: "fill" | "pad" | "scale" | "crop" | "thumb";
};

export function getContentfulImageUrl(
  url: string,
  params?: ContentfulImageParams
) {
  const completeUrl = appendHttps(url);
  if (!params) {
    return completeUrl;
  }
  const { width = "", height = "", fit = "" } = params;
  return `${completeUrl}?w=${width}&h=${height}&fit=${fit}`;
}

export function appendLocale(url: string, locale: string) {
  return `/${locale}${url}`;
}
