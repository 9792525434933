import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Head from "next/head";

import { getUrl } from "utils/env";
import { i18n } from "lib/i18n";

type Props = {
  title?: string;
};

export default function SEO(props: Props) {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const locale = i18n.language;

  const afiByEdgenda = t("afi_by_edgenda");

  const siteUrl = getUrl();
  const title = props.title
    ? `${props.title} – ${t("afi_by_edgenda")}`
    : `${t("meta_title")} | ${t("afi_by_edgenda")}`;
  const ogImageUrl = `${siteUrl}/images/og-image-${locale}.jpg`;
  const description = t("meta_description");
  const otherLocale = locale === "en" ? "fr" : "en";
  const url = `${siteUrl}/${locale}${asPath}`;
  const otherLocaleUrl = `${siteUrl}/${otherLocale}${asPath}`;
  const keywords = t("keywords");

  const schema = {
    "@context": "http://schema.org",
    "@type": "Website",
    url,
    name: title,
    description,
    author: {
      "@type": "Organization",
      name: t("afi_by_edgenda"),
      url: siteUrl,
    },
    creator: [t("afi_by_edgenda")],
    publisher: {
      "@type": "Organization",
      name: t("afi_by_edgenda"),
      url: siteUrl,
    },
    image: ogImageUrl,
    mainEntityOfPage: url,
  };

  return (
    <Head>
      <title>{title}</title>

      <meta name="description" lang={locale} content={description} />
      <meta name="author" content={afiByEdgenda} />
      <meta name="keywords" content={keywords} />
      <meta httpEquiv="content-language" content={locale} />
      <meta property="og:locale" content={locale} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:site_name" content={title} />

      <link rel="alternate" href={url} hrefLang={locale} />
      <link rel="alternate" href={otherLocaleUrl} hrefLang={otherLocale} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema),
        }}
      />
    </Head>
  );
}
