import { useTranslation } from "react-i18next";
import Image from "next/image";
import Link from "next/link";
import cx from "classix";

import linkedInLogo from "public/images/socials/linkedin.svg";

import FooterMobileColumn from "./FooterMobileColum";
import LocaleSelector from "./LocaleSelector";
import FooterColumn from "./FooterColumn";
import Container from "./Container";

import afiParEdgendaBlackLogo from "public/images/afi-par-edgenda-black.svg";
import { appendLocale } from "utils/utils";
import { i18n } from "lib/i18n";

export type FooterColumnData = {
  title: string;
  items: { title: string; href: string }[];
  rootUrl?: string;
};

export default function Footer() {
  const { t } = useTranslation();
  const locale = i18n.language;

  const siteLinks = [
    {
      title: t("design_development"),
      href: appendLocale("", locale),
    },
    {
      title: t("rapid_elearning"),
      href: appendLocale("", locale),
    },
    {
      title: t("custom_programs"),
      href: appendLocale("/programs", locale),
    },
    {
      title: t("business_coaching"),
      href: appendLocale("", locale),
    },
  ];

  const websiteColumns: FooterColumnData[] = [
    {
      title: "Services",
      items: siteLinks,
    },
  ];

  const laFamilleEdgendaColumns: FooterColumnData[] = [
    {
      title: "Edgenda",
      rootUrl: t("edgenda_root_url") as string,
      items: [
        { title: t("strategy_operations"), href: t("edgenda_strategy_url") },
        { title: t("coaching_leadership"), href: t("edgenda_coaching_url") },
        {
          title: t("emerging_technologies"),
          href: t("edgenda_technology_url"),
        },
        {
          title: t("learning"),
          href: t("edgenda_learning_url"),
        },
      ],
    },
    {
      title: t("afi_by_edgenda"),
      rootUrl: `/${i18n.language}`,
      items: siteLinks,
    },
    {
      title: t("apprentx_by_edgenda"),
      rootUrl: t("apprentx_root_url") as string,
      items: [{ title: t("b12"), href: t("apprentx_b12_url") }],
    },
    {
      title: t("afiu"),
      rootUrl: t("afiu_root_url") as string,
      items: [
        { title: t("for_individuals"), href: t("afiu_individuals_url") },
        { title: t("for_organizations"), href: t("afiu_organizations_url") },
        { title: t("live_afiu"), href: t("afiu_live_afiu_url") },
      ],
    },
  ];

  return (
    <Container
      tag="footer"
      className="bg-white pt-12 font-avenir flex flex-col"
    >
      <div className="hidden md:flex flex-row flex-wrap items-end mb-8">
        <LocaleSelector className="ml-auto text-black hover:text-black hover:underline" />
      </div>
      <Newsletter className="block md:hidden mb-8 ml-auto max-w-[500px]" />
      <div className="hidden md:block">
        <div className="flex flex-row items-start">
          <div className="flex items-start mr-7 w-[220px]">
            <Link href={`/${locale}`}>
              <Image
                src={afiParEdgendaBlackLogo}
                alt={"logo afi par edgenda"}
              />
            </Link>
          </div>
          <div className="flex flex-row gap-7 flex-wrap">
            {websiteColumns.map((column) => (
              <FooterColumn key={column.title} column={column} />
            ))}
          </div>
        </div>
        <hr className="mt-16 mb-12" />
      </div>
      <div className="flex md:hidden flex-col gap-2">
        <Link href={`/${locale}`}>
          <Image
            className="ml-auto"
            src={afiParEdgendaBlackLogo}
            alt={"logo afi par edgenda"}
          />
        </Link>
        {websiteColumns.map((column) => (
          <FooterMobileColumn key={column.title} column={column} />
        ))}
        <hr className="border-black mt-4 mb-6" />
      </div>
      <h2 className="text-r-2xl mb-2 block md:hidden">{t("edgenda_family")}</h2>
      <div className="hidden md:flex flex-row items-start">
        <div className="mr-7 min-w-[220px]">
          <h2 className="text-r-2xl max-w-[12ch]">{t("edgenda_family")}</h2>
        </div>

        <div className="flex flex-row gap-7 flex-wrap">
          {laFamilleEdgendaColumns.map((column) => (
            <FooterColumn key={column.title} column={column} />
          ))}
        </div>
      </div>
      <div className="flex md:hidden flex-col gap-2 items-start">
        {laFamilleEdgendaColumns.map((column) => (
          <FooterMobileColumn key={column.title} column={column} />
        ))}
        <hr className="border-black mt-4 mb-6 w-full" />
        <LocaleSelector className="text-black hover:text-black hover:underline" />
      </div>
      <div className="mb-10 mt-10 md:mt-20 flex flex-row gap-x-12">
        <div className="flex w-full md:w-3/5 flex-col">
          <div className="flex flex-col md:flex-row items-start justify-between gap-x-10 gap-y-4">
            <div>
              <p className="text-r-lg mb-4">
                {`${t("need_assistance")} `}
                <Link
                  className="text-black underline"
                  href={t("take_meeting_url") as string}
                >
                  {t("talk_to_consultant")}
                </Link>
                {` ${t("or_call")} `}
                <Link className="text-black underline" href="tel:+18776242344">
                  1 877 624.2344
                </Link>
              </p>
            </div>
            <div className="order-first md:order-last flex flex-row items-center gap-6 pr-5">
              <Link
                className="flex-shrink-0"
                href="https://www.linkedin.com/company/afi-u/"
              >
                <Image src={linkedInLogo} alt="LinkedIn" />
              </Link>
            </div>
          </div>
          <hr />
          <p className="text-r-sm mt-2">
            <Link
              className="underline mt-2"
              href={t("confidentiality_link") as string}
            >
              {t("privacy_policy")}
            </Link>
            {` | ${t("privacy_policy_text")}`}
          </p>
          <p className="text-r-sm mt-2">{t("edgenda_copyright")}</p>
          <a className="cky-banner-element cursor-pointer text-xs mt-2">
            {t("cookie_settings")}
          </a>
        </div>
        <div className="md:block ml-auto hidden">
          <Newsletter />
        </div>
      </div>
    </Container>
  );
}

function Newsletter({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div className={cx("flex flex-col items-end gap-y-2", className)} {...rest}>
      <p className="max-w-[35ch] text-end text-sm font-bold">
        {t("newsletter_text")}
      </p>
      <Link
        href={t("newsletter_link") || "#"}
        role="button"
        className="text-r-base hover:bg-revamp-neutral-600 rounded-[16px] bg-black px-4 py-1 font-bold text-white hover:text-white"
      >
        {t("newsletter_subscribe")}
      </Link>
    </div>
  );
}
